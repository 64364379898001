import {
  Pagination as PaginationMUI,
  PaginationItem as PaginationItemMUI,
  styled,
} from "@mui/material";
import { UsePaginationProps } from "@mui/material/usePagination/usePagination";
import { useCallback, FunctionComponent } from "react";
import { useAddQueryParams } from "../../dataAccess/QueryParams";
import { SeoAnchor } from "./anchor/SeoAnchor";

export type PaginationVariant = "traditional" | "simple";

const DISCOUNTS_PAGE_QUERY_PARAM_KEY = "page_number";
interface Props extends UsePaginationProps {
  variant?: PaginationVariant;
}
const PaginationStyled = styled(PaginationMUI)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  color: theme.palette.secondary.main,
}));

const PaginationItem = styled(PaginationItemMUI)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const Pagination: FunctionComponent<Props> = ({
  variant = "traditional",
  count,
  page,
}) => {
  const setQueryParams = useAddQueryParams();
  const PaginationItemLabelFirst = useCallback(() => <span>First</span>, []);
  const PaginationItemLabelPrev = useCallback(() => <span>Prev</span>, []);
  const PaginationItemLabelNext = useCallback(() => <span>Next</span>, []);
  const PaginationItemLabelLast = useCallback(() => <span>Last</span>, []);

  const handleChange = (page: number | null | undefined) => {
    setQueryParams([
      {
        paramKey: DISCOUNTS_PAGE_QUERY_PARAM_KEY,
        paramValue: page ? String(page) : null,
      },
    ]);
  };
  const variantProps =
    variant === "simple"
      ? {
          showLastButton: true,
          showFirstButton: true,
        }
      : {};
  const variantComp =
    variant === "simple"
      ? {
          first: PaginationItemLabelFirst,
          previous: PaginationItemLabelPrev,
          next: PaginationItemLabelNext,
          last: PaginationItemLabelLast,
        }
      : {};

  if (count === 1) {
    return <></>;
  }

  return (
    <Container>
      <PaginationStyled
        shape="rounded"
        count={count}
        {...variantProps}
        defaultPage={1}
        renderItem={(item) => {
          let disabled = false;
          if (
            (item.type === "first" || item.type === "previous") &&
            page === 1
          ) {
            disabled = true;
          }
          if (
            (item.type === "last" || item.type === "next") &&
            page === count
          ) {
            disabled = true;
          }
          let pageNumber: null | number | undefined = item.page;
          if (item.type === "previous") {
            pageNumber = page === 1 ? 1 : (page || 2) - 1;
          }
          if (item.type === "next") {
            pageNumber = page === count ? count : (page || 1) + 1;
          }

          return (
            <SeoAnchor
              href={`/?${DISCOUNTS_PAGE_QUERY_PARAM_KEY}=${pageNumber}`}
              onClick={() => !disabled && handleChange(pageNumber)}
            >
              <PaginationItem
                components={variantComp}
                {...item}
                selected={item.page === page && item.type === "page"}
                disabled={disabled}
              />
            </SeoAnchor>
          );
        }}
      />
    </Container>
  );
};

export default Pagination;
