import { FunctionComponent, useMemo, useState } from "react";
import { Button, styled, Typography } from "@mui/material";
import { HeaderSizes } from "../../../builder/useFontsLayout";
import {
  GridColumnsSettings,
  Section,
  getTilesPerSection,
} from "../../public/Grid";
import SkeletonLoader from "../SkeletonLoader";
import { Size } from "../../../types/theme";
import Pagination, { PaginationVariant } from "../Pagination";
import { TileSectionPage } from "./TileSectionPage";
import { useClearFilters } from "../Filter";

export interface TileSectionProps extends GridColumnsSettings {
  subTitle?: string;
  headerSize: Size["size"];
  sectionTileDensity: Size["size"];
  verticalSpacingSectionHeader: number;
  showSubHeader: boolean;
  showClearAll: boolean;
  section: Section;
  paginationVariant: PaginationVariant;
  hasRequestSucceedWithNoData: boolean[];
  loadingCallback?: (loading: boolean) => void;
  noDataCallback?: (loading: boolean) => void;
  setHasRequestSucceedWithNoData: (
    hasRequestSucceedWithNoData: boolean,
  ) => void;
}

const SubTitle = styled(Typography)<{
  verticalSpacingSectionHeader: number;
}>(({ theme, verticalSpacingSectionHeader }) => ({
  marginBottom: theme.typography.pxToRem(verticalSpacingSectionHeader),
}));

const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  gap: theme.spacing(1),
}));

const Title = styled(Typography)<{
  headerSize: Size["size"];
  verticalSpacingSectionHeader: number;
}>(({ theme, headerSize, verticalSpacingSectionHeader }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography[HeaderSizes[headerSize]].fontSize,
  marginBottom: theme.typography.pxToRem(verticalSpacingSectionHeader),
}));

const TitleContent = styled("span")<{
  hasActions?: boolean;
}>(({ theme, hasActions }) => ({
  marginRight: hasActions ? theme.spacing(1) : 0,
}));

const TitleButton = styled(Button)<{
  headerSize: Size["size"];
}>(({ theme, headerSize }) => ({
  color: theme.palette.other.main,
  fontSize: `calc(${theme.typography[HeaderSizes[headerSize]].fontSize} / 1.8)`,
  fontWeight: 700,
  lineHeight: 1,
  padding: 0,
  verticalAlign: "baseline",
  textTransform: "none",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
}));

const Container = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const StyledSection = styled("section")(() => ({
  marginBottom: "3rem",
}));

const StyledButton = styled(Button)(() => ({
  marginTop: "1rem",
  alignSelf: "center",
}));

const PaginationContainer = styled("div")(() => ({
  marginTop: "2rem",
}));

export const tileGap = 2;

const TilesContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  gap: `${tileGap}rem`,
  flexWrap: "wrap",
}));

// TODO: Move out the UI folder, this contains logic
const TileSection: FunctionComponent<TileSectionProps> = ({
  subTitle,
  maxNumberOfColumnsOnDesktop,
  maxNumberOfColumnsOnTablet,
  maxNumberOfColumnsOnMobile,
  paginationVariant,
  sectionTileDensity,
  headerSize,
  verticalSpacingSectionHeader,
  showSubHeader,
  showClearAll,
  section,
  hasRequestSucceedWithNoData,
  setHasRequestSucceedWithNoData,
  loadingCallback,
  noDataCallback,
}: TileSectionProps) => {
  const [page, setPage] = useState(1);
  const clearFilters = useClearFilters();
  const [pagesLoading, setPagesLoading] = useState<boolean[]>([]);
  const loading = pagesLoading.includes(true);
  loadingCallback && loadingCallback(loading);
  const tilesPerSection = useMemo(
    () => getTilesPerSection(sectionTileDensity, maxNumberOfColumnsOnDesktop),
    [sectionTileDensity, maxNumberOfColumnsOnDesktop],
  );

  const [dataAvailableStorage, setDataAvailableStorage] = useState<boolean[]>(
    [],
  );
  const dataAvailable = dataAvailableStorage.includes(true);
  noDataCallback && noDataCallback(!dataAvailable);

  // TODO: remove the "any" type
  const [sectionPagemeta, setSectionPagemeta] = useState<any>({});
  const [sectionAllPagemeta, setSectionAllPagemeta] = useState<any>({});

  // TODO: add error handling
  const tileSectionPages = useMemo(() => {
    const tiles = [];
    for (let i = 1; i <= page; i += 1) {
      tiles.push(
        <TileSectionPage
          setHasRequestSucceedWithNoData={setHasRequestSucceedWithNoData}
          maxNumberOfColumnsOnDesktop={maxNumberOfColumnsOnDesktop}
          maxNumberOfColumnsOnMobile={maxNumberOfColumnsOnMobile}
          maxNumberOfColumnsOnTablet={maxNumberOfColumnsOnTablet}
          key={i}
          section={section}
          page={i}
          loadingCallback={(loading) => {
            setPagesLoading((prev: boolean[]) => {
              const newState = [...prev];
              newState[i] = loading;
              return newState;
            });
          }}
          dataAvailableCallback={(dataAvailable) => {
            setDataAvailableStorage((prev: boolean[]) => {
              const newState = [...prev];
              newState[i] = dataAvailable;
              return newState;
            });
          }}
          sectionPageMetaCallback={setSectionPagemeta}
          sectionAllPageMetaCallback={setSectionAllPagemeta}
          pageSize={tilesPerSection}
        />,
      );
    }
    return tiles;
  }, [page, hasRequestSucceedWithNoData]);

  const showSeeMoreButton = tilesPerSection < sectionPagemeta.records;
  const lastPage = page * tilesPerSection >= sectionPagemeta.records;
  const { title } = section;
  const subTitleToDisplay = subTitle;
  return (
    // This element can't be unmounted otherwise the requests triggered by tileSectionPages are cancelled
    <StyledSection key={title} style={dataAvailable ? {} : { display: "none" }}>
      <TitleContainer>
        <Title
          verticalSpacingSectionHeader={
            showSubHeader ? 0 : verticalSpacingSectionHeader
          }
          headerSize={headerSize}
          variant="h1"
        >
          <TitleContent hasActions={showClearAll}>
            {loading && page === 1 ? <SkeletonLoader width="10rem" /> : title}
          </TitleContent>
          {showClearAll && (
            <TitleButton
              disableFocusRipple
              disableRipple
              disableTouchRipple
              headerSize={headerSize}
              onClick={clearFilters}
            >
              Clear filters
            </TitleButton>
          )}
        </Title>
      </TitleContainer>
      {showSubHeader && (
        <SubTitle verticalSpacingSectionHeader={verticalSpacingSectionHeader}>
          {loading && page === 1 ? (
            <SkeletonLoader width="10rem" />
          ) : (
            subTitleToDisplay
          )}
        </SubTitle>
      )}
      <Container>
        <TilesContainer>{tileSectionPages}</TilesContainer>
        {section.type !== "all" && dataAvailable && showSeeMoreButton && (
          <StyledButton
            onClick={() => setPage((prev) => (lastPage ? 1 : prev + 1))}
          >
            See {lastPage ? "Less" : "More"}
          </StyledButton>
        )}
      </Container>
      {section.type === "all" && (
        <PaginationContainer>
          <Pagination
            page={sectionAllPagemeta.current}
            count={
              sectionAllPagemeta.last === undefined
                ? sectionAllPagemeta.current
                : sectionAllPagemeta.last
            }
            variant={paginationVariant}
          />
        </PaginationContainer>
      )}
    </StyledSection>
  );
};

export default TileSection;
